.cards-wrapper {
  width: 40%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 3%; }


.title-card-list {
  text-align: center; }

