.card {
  margin: auto;
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40%; }

.card-title {
  font-size: 1.25rem;
  font-weight: normal;
  color: #777;
  margin: 0; }

.card-value {
  font-size: 4rem;
  font-weight: 800;
  margin: 0; }


.card-detail {
  padding: .2rem 1rem;
  background-color: #333;
  border-radius: .4rem;
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  color: #555; }
