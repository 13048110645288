

$color-text-base: #2f2f33;
$text-secondary-color: #757680;

.App {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0; }


.wrapper {
  height: 100%;
  width: 100%; }

.wrapper:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url(https://www.biospace.com/getasset/ff893178-9ffa-4b80-943e-1d8eb6e3a783/);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover; }

.main-page {
  height: 100%;
  width: 100%;
  position: relative;


  .logo {
    height: 5rem;
    margin-left: 1rem; } }

.flex {
  display: flex;
  flex-wrap: wrap; }


.elements-in-row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0% 2%; }

.align-text-center {
  text-align: center; }

.wrapper-title {
  justify-content: center;
  align-items: center;
  width: 100%;
  @media(orientation: landscape) {
    height: 15%; }
  @media(orientation: portrait) {
    height: 8%; } }


.wrapper-card-list {
  @media(orientation: landscape) {
    height: 100%;
    width: 40%; }
  @media(orientation: portrait) {
    height: 50%;
    width: 100%; } }

.wrapper-graph {
  @media(orientation: landscape) {
    height: 95%;
    width: 50%; }
  @media(orientation: portrait) {
    height: 50%;
    width: 100%; } }

.wrapper-main-content {
  @media(orientation: landscape) {
    height: 60%; }
  @media(orientation: portrait) {
    height: 67%; } }

.wrapper-footer {
  align-self: flex-end;
  @media(orientation: landscape) {
    width: 100%; }
  @media(orientation: portrait) {
    align-self: flex-end; } }

.h-100 {
  height: 100%; }

.h-20 {
  height: 20%; }


.w-100 {
  width: 100%; }


.qr-code {
  border: 1rem solid white;
  width: auto !important;
  max-height: 200px !important;
  @media(orientation: landscape) {
    height: 100% !important; }
  @media(orientation: portrait) {
    height: 50% !important; } }

