$color-text-base: #2f2f33;
$text-secondary-color: #757680;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

h1 {
  font-size: 3rem; }

html, body, #root {
  width: 100%;
  height: 100%;
  background-color: white; }

body,
input,
button,
textarea {
  font: 500 1rem Poppins;
  color: $color-text-base; }
